import React from 'react';
import * as Slider from '@radix-ui/react-slider';

import './RangeSlider.scss';

const RangeSlider = (props) => {
  return (
    <Slider.Root className="SliderRoot" {...props}>
      <Slider.Track className="SliderTrack">
        <Slider.Range className="SliderRange" />
      </Slider.Track>
      <Slider.Thumb className="SliderThumb" aria-label="Volume" />
    </Slider.Root>
  );
};

export default RangeSlider;
