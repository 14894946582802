import React, { useState } from 'react';
import Metadata from '../components/Metadata';

import PageLayout from '../layouts/Page';
import Container from '../components/Container';
import RangeSlider from '../components/RangeSlider';
import HubspotForm from '../components/HubspotForm';

import './roi.scss';

function RoiPage() {
  return (
    <PageLayout>
      <Metadata
        title="Calculate your DevX ROI with Okteto"
        description="Understand the potential savings and ROI that you can achieve by adopting Okteto Platform with automated and self-service environments."
        image="https://www.okteto.com/roi-og.png"
      />
      <Container className="RoiContainer CalculatorGated">
        <section className="PageIntro">
          <h1 className="PageIntroTitle">
            Calculate your DevX ROI with Okteto
          </h1>
          <p className="PageIntroDescription">
            Understand the potential savings and ROI that you can achieve by
            adopting Okteto Platform with automated and self-service
            environments.
          </p>
        </section>
        <Calculator />
        <section className="PageIntro PageIntroSecondary">
          <h2 className="SectionIntroTitle">
            See how DevX impact and savings are calculated
          </h2>
          <p className="PageIntroDescription">
            Calculations are estimates collected from industry surveys and
            Okteto customer results. The hours listed below reflect monthly time
            spent.
          </p>
        </section>
        <section className="RoiGated">
          <section className="RoiSection">
            <h3 className="RoiSectionTitle">Developer productivity</h3>
            <p className="SectionIntro">
              The following areas show an estimate of <em>monthly</em> time
              spent on non-development activities that impact productivity.
            </p>
            <div className="LineItem">
              <p className="LineItemDescription">
                Time spent learning, researching fixing environments
              </p>
              <div className="LineItemContainer">
                <div className="LineItemValues">
                  <strong className="LineItemAverage">Monthly Average</strong>
                  <span className="LineItemAverageValue">40 hrs</span>
                </div>
                <div className="LineItemValues WithOkteto">
                  <strong className="LineItemAverage">With Okteto</strong>
                  <span className="LineItemAverageValue">8 hrs</span>
                </div>
              </div>
            </div>
            <div className="LineItem">
              <p className="LineItemDescription">
                Wait time for approvals, provisioning and preview environment
              </p>
              <div className="LineItemContainer">
                <div className="LineItemValues">
                  <strong className="LineItemAverage">Monthly Average</strong>
                  <span className="LineItemAverageValue">40 hrs</span>
                </div>
                <div className="LineItemValues WithOkteto">
                  <strong className="LineItemAverage">With Okteto</strong>
                  <span className="LineItemAverageValue">8 hrs</span>
                </div>
              </div>
            </div>
            <div className="LineItem">
              <p className="LineItemDescription">
                Time spent on bug fixes and rework
              </p>
              <div className="LineItemContainer">
                <div className="LineItemValues">
                  <strong className="LineItemAverage">Monthly Average</strong>
                  <span className="LineItemAverageValue">16 hrs</span>
                </div>
                <div className="LineItemValues WithOkteto">
                  <strong className="LineItemAverage">With Okteto</strong>
                  <span className="LineItemAverageValue">4 hrs</span>
                </div>
              </div>
            </div>
            <div className="BeforeAfter">
              <div>
                <h4 className="BeforeAfterTitle">Before Okteto</h4>
                <p className="BeforeAfterDescription">
                  Development teams are losing significant, valuable time
                  dealing with environment set up, troubleshooting and wait
                  times. Studies show that developers can spend more than 50% of
                  their work time addressing these topics. These inefficiencies
                  not only delay project timelines but also impact overall team
                  morale and product quality.
                </p>
              </div>
              <div>
                <h4 className="BeforeAfterTitle WithOkteto">With Okteto</h4>
                <p className="BeforeAfterDescription">
                  Okteto's self-service ephemeral environments abstract away
                  manual configurations and local dependencies, eliminating
                  unnecessary development drift. By seamlessly provisioning
                  production-like environments, Okteto frees up teams to focus
                  on innovation, removing the cognitive load of setup and
                  maintenance. Provision environments that developers can trust
                  and allow them to dive straight into their work with
                  confidence and efficiency.
                </p>
              </div>
            </div>
          </section>
          <section className="RoiSection">
            <h3 className="RoiSectionTitle">Platform efficiency</h3>
            <p className="SectionIntro">
              The following section shows an estimate of <em>monthly</em> time
              spent on areas that impact platform engineering effectiveness
            </p>
            <div className="LineItem">
              <p className="LineItemDescription">
                Platform help desk: supporting and servicing developers
              </p>
              <div className="LineItemContainer">
                <div className="LineItemValues">
                  <strong className="LineItemAverage">Monthly Average</strong>
                  <span className="LineItemAverageValue">24 hrs</span>
                </div>
                <div className="LineItemValues WithOkteto">
                  <strong className="LineItemAverage">With Okteto</strong>
                  <span className="LineItemAverageValue">4 hrs</span>
                </div>
              </div>
            </div>
            <div className="LineItem">
              <p className="LineItemDescription">
                Onboarding: amount of time spent to onboard a new developer
              </p>
              <div className="LineItemContainer">
                <div className="LineItemValues">
                  <strong className="LineItemAverage">Monthly Average</strong>
                  <span className="LineItemAverageValue">16 hrs</span>
                </div>
                <div className="LineItemValues WithOkteto">
                  <strong className="LineItemAverage">With Okteto</strong>
                  <span className="LineItemAverageValue">4 hrs</span>
                </div>
              </div>
            </div>
            <div className="LineItem">
              <p className="LineItemDescription">
                Contractors: support time for onboarding and governance of
                contractors
              </p>
              <div className="LineItemContainer">
                <div className="LineItemValues">
                  <strong className="LineItemAverage">Monthly Average</strong>
                  <span className="LineItemAverageValue">8 hrs</span>
                </div>
                <div className="LineItemValues WithOkteto">
                  <strong className="LineItemAverage">With Okteto</strong>
                  <span className="LineItemAverageValue">1.6 hrs</span>
                </div>
              </div>
            </div>
            <div className="BeforeAfter">
              <div>
                <h4 className="BeforeAfterTitle">Before Okteto</h4>
                <p className="BeforeAfterDescription">
                  Platform engineering teams struggle with manual provisioning
                  and de-provisioning of environments, leading to
                  inefficiencies, resource bottlenecks and delays. Onboarding
                  new developers is often time consuming due to extensive setup
                  requirements for each individual. A lack of automation greatly
                  impact the teams efficiency, hindering productivity.
                </p>
              </div>
              <div>
                <h4 className="BeforeAfterTitle WithOkteto">With Okteto</h4>
                <p className="BeforeAfterDescription">
                  By applying automation to your development environment
                  provisioning, platform teams can realize significant time
                  savings through a designed strategy. Centralized control and
                  standardized setups ensure consistency, accelerating
                  time-to-market and boosting productivity.
                </p>
              </div>
            </div>
          </section>
          <section className="RoiSection">
            <h3 className="RoiSectionTitle">Staffing budget efficiency</h3>
            <p className="SectionIntro">
              The following areas are considered to calculate estimate cost of
              turnover
            </p>
            <div className="LineItem">
              <p className="LineItemDescription">
                Developer satisfaction: turnover rate
              </p>
              <div className="LineItemContainer">
                <div className="LineItemValues">
                  <strong className="LineItemAverage">Monthly Average</strong>
                  <span className="LineItemAverageValue">5%</span>
                </div>
                <div className="LineItemValues WithOkteto">
                  <strong className="LineItemAverage">With Okteto</strong>
                  <span className="LineItemAverageValue">2.5%</span>
                </div>
              </div>
            </div>

            <div className="BeforeAfter">
              <div>
                <h4 className="BeforeAfterTitle">Before Okteto</h4>
                <p className="BeforeAfterDescription">
                  Without a focus on developer experience, companies struggle to
                  retain top talent and maintain continuity in their development
                  efforts. Teams must evaluate turnover rates and consider the
                  time, resource and business opportunity costs of replacing
                  developers.
                </p>
              </div>
              <div>
                <h4 className="BeforeAfterTitle WithOkteto">With Okteto</h4>
                <p className="BeforeAfterDescription">
                  By prioritizing developer satisfaction and providing
                  streamlined environments, Okteto helps companies retain top
                  talent, preserving valuable institutional knowledge and
                  keeping the development engines running. This results in
                  significant cost savings by reducing turnover-related expenses
                  and time to market.
                </p>
              </div>
            </div>
          </section>
        </section>
      </Container>
    </PageLayout>
  );
}

const Calculator = () => {
  const [numberOfSoftwareDev, setNumberOfSoftwareDev] = useState(10);
  const [salaryOfSoftwareDev, setSalaryOfSoftwareDev] = useState(50000);
  const [numberOfPlatformDev, setNumberOfPlatformDev] = useState(1);
  const [salaryOfPlatformDev, setSalaryOfPlatformDev] = useState(50000);

  const annual_savings = Maths({
    numberOfSoftwareDev,
    salaryOfSoftwareDev,
    numberOfPlatformDev,
    salaryOfPlatformDev,
  });
  return (
    <div className="Calculator">
      <div className="CalculatorGrid">
        <div>
          <p className="CalculatorTitle">Development team</p>
          <div className="CalculatorSection">
            <p className="CalculatorSubtitle">Number of software developers</p>
            <div className="CalculatorRangerContainer">
              <RangeSlider
                min={10}
                max={1000}
                defaultValue={[numberOfSoftwareDev]}
                onValueChange={(value) => setNumberOfSoftwareDev(value[0])}
              />
              <span
                className="CalculatorVariableValue"
                data-roi-number-software-dev={numberOfSoftwareDev}
              >
                {numberOfSoftwareDev}
              </span>
            </div>
            <p className="CalculatorSubtitle">
              Average salary of software developers
            </p>
            <div className="CalculatorRangerContainer">
              <RangeSlider
                min={50000}
                max={250000}
                step={1000}
                defaultValue={[salaryOfSoftwareDev]}
                onValueChange={(value) => setSalaryOfSoftwareDev(value[0])}
              />
              <span
                className="CalculatorVariableValue"
                data-roi-salary-software-dev={salaryOfSoftwareDev}
              >
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                }).format(salaryOfSoftwareDev)}
              </span>
            </div>
          </div>
        </div>
        <div>
          <p className="CalculatorTitle">Platform Team</p>
          <div className="CalculatorSection">
            <p className="CalculatorSubtitle">Number of platform engineers</p>
            <div className="CalculatorRangerContainer">
              <RangeSlider
                min={1}
                max={50}
                defaultValue={[numberOfPlatformDev]}
                onValueChange={(value) => setNumberOfPlatformDev(value[0])}
              />
              <span
                className="CalculatorVariableValue"
                data-roi-number-platform-dev={numberOfPlatformDev}
              >
                {numberOfPlatformDev}
              </span>
            </div>
            <p className="CalculatorSubtitle">
              Average salary of platform engineers
            </p>
            <div className="CalculatorRangerContainer">
              <RangeSlider
                min={50000}
                max={250000}
                step={1000}
                defaultValue={[salaryOfPlatformDev]}
                onValueChange={(value) => setSalaryOfPlatformDev(value[0])}
              />
              <span
                className="CalculatorVariableValue"
                data-roi-salary-platform-dev={salaryOfPlatformDev}
              >
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0,
                }).format(salaryOfPlatformDev)}
              </span>
            </div>
          </div>
        </div>
        <div className="CalculatorOther">
          <p className="CalculatorTitle">Other calculations</p>
          <div className="CalculatorSection CalculatorOtherSection">
            <div className="CalculatorOtherLabel">
              <p className="CalculatorSubtitle">Local hardware (annual cost)</p>
              <span className="CalculatorFixedValue">$2000/person</span>
            </div>
            <div className="CalculatorOtherLabel">
              <p className="CalculatorSubtitle">
                Cloud-native dev tools (annual cost)
              </p>
              <span className="CalculatorFixedValue">$1000/person</span>
            </div>
          </div>
        </div>
      </div>
      <div className="CalculatorResults">
        <div>
          <p className="CalculatorTitle CalculatorTitleInverted">
            Your annual producitivty gains with Okteto
          </p>
          <p
            className="CalculatorResultsValue"
            data-roi-savings={annual_savings.savings}
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }).format(annual_savings.savings)}
          </p>
        </div>
        <div className="CalculatorForm">
          <h3 className="CalculatorTitle CalculatorTitleDark">
            Uncover your results
          </h3>
          <p className="CalculatorResultsIntro">
            View your savings and how it was calculated
          </p>
          <HubspotForm
            formId="c94bf151-c61e-4380-983d-4ad43c064802"
            dontUseChiliPiper
          />
        </div>
      </div>
    </div>
  );
};

const Maths = ({
  numberOfSoftwareDev,
  salaryOfSoftwareDev,
  numberOfPlatformDev,
  salaryOfPlatformDev,
}) => {
  const HOURS_WORKED_PER_YEAR = 2080;
  const HARD_COSTS_DEV = 2000;
  const HARD_COSTS_PLATFORM = 1000;

  const TOTAL_COSTS_DEV =
    numberOfSoftwareDev * salaryOfSoftwareDev +
    numberOfSoftwareDev * HARD_COSTS_DEV;
  const TOTAL_COSTS_PLATFORM =
    numberOfPlatformDev * salaryOfPlatformDev +
    numberOfPlatformDev * HARD_COSTS_PLATFORM;

  const HOURS_LOST_DEV = 56 * 12;
  const HOURS_LOST_PLATFORM = 48 * 12;
  const LOST_COSTS_DEV =
    (TOTAL_COSTS_DEV / HOURS_WORKED_PER_YEAR) * HOURS_LOST_DEV;
  const LOST_COSTS_PLATFORM =
    (TOTAL_COSTS_PLATFORM / HOURS_WORKED_PER_YEAR) * HOURS_LOST_PLATFORM;
  const TURNOVER_COST = numberOfSoftwareDev * 10000;
  const LOST_COSTS_TOTAL = LOST_COSTS_DEV + LOST_COSTS_PLATFORM + TURNOVER_COST;

  const OKTETO_HOURS_LOST_DEV = 20 * 12;
  const OKTETO_HOURS_LOST_PLATFORM = 9.6 * 12;
  const OKTETO_LOST_COSTS_DEV =
    (TOTAL_COSTS_DEV / HOURS_WORKED_PER_YEAR) * OKTETO_HOURS_LOST_DEV;
  const OKTETO_LOST_COSTS_PLATFORM =
    (TOTAL_COSTS_PLATFORM / HOURS_WORKED_PER_YEAR) * OKTETO_HOURS_LOST_PLATFORM;
  const OKTETO_TURNOVER_COST = numberOfSoftwareDev * 5000;
  const OKTETO_LOST_COSTS_TOTAL =
    OKTETO_LOST_COSTS_DEV + OKTETO_LOST_COSTS_PLATFORM + OKTETO_TURNOVER_COST;
  const OKTETO_LICENSES_COST = numberOfSoftwareDev * 99 * 12;

  const OKTETO_TOTAL = OKTETO_LOST_COSTS_TOTAL + OKTETO_LICENSES_COST;

  const SAVINGS = LOST_COSTS_TOTAL - OKTETO_TOTAL;

  const ROI_SAVINGS = SAVINGS - LOST_COSTS_TOTAL;

  return {
    savings: Math.trunc(SAVINGS),
  };
};

export default RoiPage;
